import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { ContentBar } from '@myneva-portals/projects/src/components';

export const Dashboard: React.FC = () => {
  const { breadcrumbs } = useSelector(navigationSelector);

  const actionPanel = <div></div>;

  const { t } = useTranslation();

  return (
    <div>
      <ContentBar
        breadcrumbsItems={breadcrumbsUtils.getBreadcrumbs(breadcrumbs)}
        actionPanelContent={actionPanel}
        translation={t}
      />
      Dashboard
    </div>
  );
};
